import styled from 'styled-components';
import IcFullLogo from 'assets/ic_full_logo.svg';
import { media } from 'utils/media';
import { useState } from 'react';
import IcMenu from 'assets/ic_menu.svg';
import IcInstagram from 'assets/ic_instagram.svg';
import IcLinkedin from 'assets/ic_linkedin.svg';
import { Modal } from 'react-bootstrap';
import { SELECTED_THEME, themeColors } from 'utils/theme';

export const HeaderWebsite = () => {
  const [showMenu, setShowMenu] = useState(false);

  const renderMenuMobile = () => {
    return (
      <Modal
        className="modal-dialog-menu"
        style={{
          backgroundColor: 'transparent',
          padding: '8px 16px',
          marginTop: '5.6rem',
          borderRadius: '0.8rem',
        }}
        fullscreen={false}
        show={showMenu}
        onHide={() => setShowMenu(false)}
      >
        <Modal.Body
          style={{
            backgroundColor: themeColors[SELECTED_THEME].primary,
            borderRadius: '0.8rem',
            height: '18rem',
          }}
        >
          <div
            style={{
              width: '100%',
            }}
          >
            <a href="/for-artist" className="menu-text-header">
              <div>FOR ARTISTS</div>
            </a>
            <div style={{ marginTop: '4rem' }}>
              <a href="/">
                <img
                  src={IcFullLogo}
                  alt="logo"
                  className="logo"
                  style={{ width: '7rem', height: '1.8rem' }}
                />
              </a>
            </div>
            <div
              style={{ display: 'flex', gap: '0.8rem', marginTop: '2.4rem' }}
            >
              <a
                href="https://www.instagram.com/fanfliq/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="social"
                  src={IcInstagram}
                  style={{ width: '2.4rem', height: '2.4rem' }}
                />
              </a>
              <a
                href="https://www.linkedin.com/showcase/fanfliq/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="social"
                  src={IcLinkedin}
                  style={{ width: '2.4rem', height: '2.4rem' }}
                />
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <Parent>
      <a href="/">
        <img src={IcFullLogo} alt="logo" className="logo" />
      </a>
      <DesktopMenu className="gap-32">
        <a href="/for-artist" className="menu-text-header hover-link-ff">
          <div>FOR ARTISTS</div>
        </a>
      </DesktopMenu>
      <Menu src={IcMenu} alt="menu" onClick={() => setShowMenu(true)} />
      {renderMenuMobile()}
    </Parent>
  );
};

const Parent = styled.div`
  width: 100%;
  display: flex;
  gap: 3.2rem;
  position: fixed;
  z-index: 20;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(12px);
  justify-content: space-between;
  ${media.mobile`padding: 0 1.6rem; height: 5.6rem`};
  ${media.small`padding: 0 1.6rem; height: 5.6rem`};
  ${media.medium`padding: 0 6.4rem; height: 8rem`};
  align-items: center;
`;

const Menu = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 6.4rem;
  ${media.mobile`display: flex`};
  ${media.small`display: flex`};
  ${media.medium`display: none`};
`;

const DesktopMenu = styled.div`
  ${media.mobile`display: none`};
  ${media.small`display: none`};
  ${media.medium`display: flex`};
`;
